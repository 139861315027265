<template>

  <div class="page">
    <vue-confirm-dialog/>
    <v-row class="text-center intro">
      <v-col cols="12" class="pb-0">
        <a href="/"><v-img
            :src="require('../assets/logo-black.svg')"
            class=""
            contain
            height="200"
        /></a>
      </v-col>
    </v-row>

    <v-container>

      <div v-if="stored">
        <v-row class="text-center">
          <v-col class="mb-4">

            <h1 class="display-1 font-weight-bold mb-5">
              Děkujeme. Údaje jsme úspěšně uložili.
            </h1>
            <a href="/">Zpět na hlavní stránku</a>
          </v-col>
        </v-row>
      </div>

      <div v-if="!stored && paymentState > 1">
        <v-row class="text-center">
          <v-col class="mb-4">

            <h1 class="display-1 font-weight-bold mb-5">
              Objednávku se bohužel nepodařilo uhradit.
            </h1>
            <p>
              Prosím vytvořte novou a zkuste to znovu.
            </p>
            <p v-if="paymentError">
              Chyba: {{ paymentError }}
            </p>
          </v-col>
        </v-row>
      </div>

      <div v-if="!stored && paymentState === 1">
      <v-row class="text-center">
        <v-col class="mb-4">

          <h1 class="display-1 font-weight-bold mb-5">
            Děkujeme. Platba proběhla v pořádku.
          </h1>
          <p>Prosím zadejte údaje o dětech.</p>
        </v-col>
      </v-row>
      <v-form :disabled="!editable">
      <v-row class="text-center pb-10">
        <v-col cols="12">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <form @submit.prevent="onSubmit">

              <div class="child text-left" ref="child" v-for="(item, key) in children">

                <h2 class="text-center"><span>{{ key + 1 }}</span>) {{ item.product }}</h2>

                
                <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                  <v-text-field v-model="item.name" label="Jméno a příjmení *"/>
                  <span class="validationMessage">{{ errors[0] }}</span>
                </ValidationProvider>
                <v-text-field v-model="item.nickname" label="Jméno dítěte na jmenovce (jak chce být oslovován/a)"/>

                <v-row>
                  <v-col cols="12">
                    <p class="mb-0">
                      Datum narození:
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <v-select
                          :items="day"
                          label="Den *"
                          v-model="item.birthday.day"
                      ></v-select>
                      <span class="validationMessage">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <v-select
                          :items="month"
                          label="Měsíc *"
                          v-model="item.birthday.month"
                      ></v-select>
                      <span class="validationMessage">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <v-select
                          :items="year"
                          label="Rok *"
                          v-model="item.birthday.year"
                      ></v-select>
                      <span class="validationMessage">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-select
                      :items="insuranceAgencies"
                      item-value="name"
                      item-text="name"
                      v-model="item.insurance"
                      label="Zdravotní pojišťovna *"
                  ></v-select>
                  <span class="validationMessage">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                  <v-text-field v-model="item.address" placeholder="Ulice, č.p., město, PSČ" label="Bydliště *"/>
                  <span class="validationMessage">{{ errors[0] }}</span>
                </ValidationProvider>

                <div class="pt-10">
                  <h3>Zákonný zástupce *</h3>
                  <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                    <v-text-field v-model="item.parents[0].name" label="Jméno a příjmení *"/>
                    <span class="validationMessage">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <v-row>
                    <v-col cols="6">
                      <ValidationProvider rules="required|phoneNumber" v-slot="{ errors }">
                        <v-text-field v-model="item.parents[0].phone" placeholder="+420 " label="Mobil *"/>
                        <span class="validationMessage">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="6">
                      <ValidationProvider rules="required|email" v-slot="{ errors }">
                        <v-text-field v-model="item.parents[0].email" placeholder="Vyplňte emailovou adresu ve správném tvaru" label="E-mail *"/>
                        <span class="validationMessage">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </div>

                <div class="pt-7" v-if="false">
                  <h3>Další zástupce (druhý rodič případně jiný člen rodiny)</h3>
                  <v-text-field v-model="item.parents[1].name" label="Jméno a příjmení"/>
                  <v-row>
                    <v-col cols="6">
                      <ValidationProvider rules="phoneNumber" v-slot="{ errors }">
                        <v-text-field v-model="item.parents[1].phone" placeholder="+420 " label="Mobil *"/>
                        <span class="validationMessage">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="6">
                      <ValidationProvider rules="email" v-slot="{ errors }">
                        <v-text-field v-model="item.parents[1].email" label="E-mail"/>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </div>

                <div class="pt-7">
                  <h3>Informace o dítěti pro pořadatele tábora</h3>
                  <span class="charsLimiter">Zbývá {{ maxCharsInTextarea - item.info.health.length }} znaků</span>
                  <v-textarea
                      label="Informace o zdravotním stavu dítěte a o dalších omezeních (uveďte prosím zdravotní omezení, rizika, alergie, užívané léky, stravovací omezení-návyky atd.):"
                      value=""
                      hint=""
                      :maxlength="maxCharsInTextarea"
                      v-model="item.info.health"
                  ></v-textarea>

                  <v-row>
                    <v-col cols="6">
                      <v-radio-group v-model="item.handover">
                        <template v-slot:label>
                          <div>Dítě ráno</div>
                        </template>
                        <v-radio value="yes">
                          <template v-slot:label>
                            <div>předám osobně</div>
                          </template>
                        </v-radio>
                        <v-radio value="no">
                          <template v-slot:label>
                            <div>přijde samostatně</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6">
                      <v-radio-group v-model="item.pickup">
                        <template v-slot:label>
                          <div>Dítě odpoledne</div>
                        </template>
                        <v-radio value="yes">
                          <template v-slot:label>
                            <div>převezmu osobně</div>
                          </template>
                        </v-radio>
                        <v-radio value="no">
                          <template v-slot:label>
                            <div>odejde samostatně</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <span class="charsLimiter">Zbývá {{ maxCharsInTextarea - item.info.other.length }} znaků</span>
                  <v-textarea
                      label="Případné další upozornění týkající se dítěte"
                      value=""
                      hint=""
                      :maxlength="maxCharsInTextarea"
                      v-model="item.info.other"
                  ></v-textarea>

                </div>

              </div>

              <v-btn v-if="editable" color="primary" :disabled="invalid" @click="onSubmit">Odeslat</v-btn>




            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
      </v-form>
      </div>
    </v-container>

  </div>

</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required} from 'vee-validate/dist/rules'
import PhoneNumber from "awesome-phonenumber";


const baseUrl = process.env.VUE_APP_BASE_URL
const projectId = process.env.VUE_APP_PROJECT_ID
const productCategoryId = process.env.VUE_APP_PRODUCT_CATEGORY_ID

const payment = {
  paymentMethodId: process.env.VUE_APP_PAYMENT_METHOD_ID,
  shippingMethodId: process.env.VUE_APP_SHIPPING_METHOD_ID,
  eshopId: process.env.VUE_APP_ESHOP_ID
}

extend('presenceString', value => {
  if (value.length > 1) {
    return true
  }

  return 'Prosím zadejte alespoň 2 znaky'
})

extend('email', {
  ...email,
  message: 'Nevalidni mail'
})

extend('required', {
  ...required,
  message: 'Toto pole je povinné'
})

extend('phoneNumber', value => {
  let phone = new PhoneNumber(value)
  if (phone.isValid()) {
    return true
  }
  return 'Zadejte prosím telefon ve správném tvaru včetně převolby (+420)'
});

export default {
  name: 'ChildrenDetails',

  components: {
    ValidationProvider, ValidationObserver, extend
  },

  props: [
      'eshopId',
      'orderId',
      'alreadyPaid',
      'editable',
  ],

  data: () => ({
    paymentState: 0,
    stored: false,
    orderUuid: null,
    customer: {
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      address: {
        street: null,
        town: null,
        zip: null
      },
      isCompany: false,
      companyName: null,
      companyCRN: null,
      companyVAT: null,
    },
    children: [],
    insuranceAgencies: [],
    day: [...Array(31).keys()].map(i => ("0" + (i + 1)).slice(-2)),
    month: [...Array(12).keys()].map(i => ("0" + (i + 1)).slice(-2)),
    year: [...Array(9).keys()].map(i => (i + new Date().getFullYear()-16).toString()),
    gdprAgree: false,

    currencyFormatter: new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    maxCharsInTextarea: 300
  }),
  mounted() {
    this.checkPayment();

    this.fetchData();
  },
  computed: {
  },
  methods: {
    checkPayment() {

      if(this.alreadyPaid) {
        this.paymentState = 1;
        return;
      }

      let urlParams = window.location.search;

      axios.get(`${baseUrl}/payment/feedback/` + this.eshopId + urlParams)
          .then((res) => {
            this.paymentState = res.data.orderPaymentState;
            this.paymentError = res.data.resultText;
          })
          .catch((error) => {
            this.handleNetworkError(error)
          }).finally(() => {

      });
    },
    fetchData() {
      let ctx = this;
      axios.all([
        axios.get(`${baseUrl}/shoppingcart/` + this.eshopId + `/` + this.orderId),
        axios.get(`${baseUrl}/codelist/HealthInsurance`)
      ])
          .then(axios.spread((responseOrder, responseHealth) => {

            ctx.orderUuid = responseOrder.data.id;

            ctx.insuranceAgencies = responseHealth.data.map(agency => ({
              id: agency.id,
              name: agency.name
            }))

            ctx.children = responseOrder.data.items.map(item => ({
              name: item.customData.name,
              nickname: item.customData.nickname,
              birthday: {
                day: item.customData.birthday.substr(8,2),
                month: item.customData.birthday.substr(5,2),
                year: item.customData.birthday.substr(0,4)
              },
              insurance: item.customData.insurance,
              address: item.customData.address,
              parents: item.customData.parents,
              info: {
                health: item.customData.info?.health,
                other: item.customData.info?.other,
              },
              handover: item.customData.handover,
              pickup: item.customData.pickup,
              productId: item.term,
              product: item.name,
              orderItemId: item.id
            }));                
          }))
          .catch((error) => {
            this.handleNetworkError(error)
          })
    },
    handleNetworkError(error) {
      this.$confirm({
        title: 'Chyba',
        message: `${error}`,
        button: {
          yes: 'OK'
        }
      })
    },
    async onSubmit() {
      const success = await this.$refs.form.validate()
      if (!success) {
        return
      }
      const items = []
      this.children.forEach(child => {
        child.birthday = moment(`${child.birthday.year}-${('0' + child.birthday.month).slice(-2)}-${('0' + child.birthday.day).slice(-2)}`)
            .format('YYYY-MM-DD')
        const customData = child
        customData.type = 'SummerCamp'
        items.push({id: child.orderItemId, customData: customData})
      })

      const formData = {
        items: items,
      }

      this.$nextTick(() => {
        this.$refs.form.reset()
      })
      axios.patch(`${baseUrl}/shoppingcart/` + this.orderUuid, formData)
          .then((res) => {
            this.stored = true;
          })
          .catch((error) => {
            this.handleNetworkError(error)
          }).finally(() => {

      })
    },
  },
}
</script>

<style>

.intro {
  background: #201d1e;
  margin-bottom: 50px !important;
}

.container {
  max-width: 1000px !important;
}

.v-input--radio-group legend.v-label {
  font-size: 16px !important;
  margin-bottom: 10px;
}

.child {
  padding: 40px 40px 10px 40px;
  border: 1px solid #aaa;
  margin: 20px auto 40px auto;
  width: 100%;
  clear: both;
  float: none;
  display: block;
  position: relative;
}

.child .delete {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.v-textarea textarea {
  height: 100px;
}

.validationMessage {
  color: red;
  font-size: 0.9rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ECECEC;
}


</style>
